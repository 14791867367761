import {Card, Col, Row} from 'react-bootstrap'
import ButtonBlue from './ButtonBlue';
import ButtonGrey from './ButtonGrey';

import "../Design/StakingCards.css"
import "../Design/Home.css"
import "../Design/Collectibles.css"

function UnStakedCard(props) {
    return (

        <>
        <Col xs={12} sm={6} md={4}>
            <div className="StakeKnights">
                <Card className="KnightCards">
                    <Card.Img variant="top" src={props.image1}/>
                    <Card.Text>
                    <p className='specialP'>{props.txt1}</p>
                    </Card.Text>
                    <div className='buttonsSkate'>

                        <ButtonBlue onClick={props.onClickStake}>STAKE</ButtonBlue>

                    </div>
                </Card>
            </div>
        </Col>
        </>

    )
}
export default UnStakedCard;
