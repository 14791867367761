import '../Design/ButtonBlue.css';


function ButtonLeft(props) {

    
    return (
        <div className="button1" onClick={props.onClick}>
            <span className="buttonBlue"> {props.children}
            </span>
            <div className="hoverEffect"></div> 
        </div>
    );
}
export default ButtonLeft;