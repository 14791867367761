import {Card} from 'react-bootstrap'
import "../Design/KnightsCards.css"

function CardKnight(props) {
    return ( 
        <div className="MeetKnights">
                <Card className="KnightCards">
                    <Card.Img variant="top" src={props.image}/>
                </Card>
        </div>
    )
}
export default CardKnight;