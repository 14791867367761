import '../Design/ButtonBlue.css';


function ButtonGrey(props) {

    
    return (
        <div className="button1 buttonGrey" onClick={props.onClick}>
            <span className="buttonBlue"> {props.children}
            </span>
            <div className="hoverEffect"></div> 
        </div>
    );
}
export default ButtonGrey;