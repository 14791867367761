import {useRef,useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import ButtonBlue from '../Components/ButtonBlue';
import ButtonGrey from '../Components/ButtonGrey';
import GalleryCards from '../Components/GalleryCards'
import { getAllNft } from '../apiEndpoints';
import { idNft } from '../config';
import axios from 'axios';
import {useGetLoginInfo, refreshAccount, transactionServices,useGetNetworkConfig, logout,useGetAccountInfo} from "@elrondnetwork/dapp-core";
import abiFile from "../elrondknightstacking.abi.json";
import { contractAddressStaking } from '../config';

import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";



import {
    Address,
    ContractFunction,
    Query,
    U64Value,
    AbiRegistry,
    SmartContractAbi,
    SmartContract,
    ResultsParser,
    AddressValue
  } from '@elrondnetwork/erdjs';


import '../Design/Home.css';
import '../Design/Collectibles.css';
import '../Design/Gallery.css';

import search from '../Assets/search.svg';
import Jedi from '../Assets/Jedi.jpg';

const Basic = 'Basic';
const Rare = 'Rare';
const Exclusive = 'Exclusive';

function Gallery(props) {

    const { network } = useGetNetworkConfig();
    const { address } = useGetAccountInfo();

    const [allNfts,setAllNfts] = useState([]);
    const [filtre,setFiltre] = useState();
    const [research,setResearch] = useState();
    const [activeRarity,setActiveRarity] = useState("");
    const [rarityCat,setRarityCat] = useState({});

    useEffect(() => {
      axios.get(getAllNft(network.apiAddress,idNft))
        .then(function(response){
          setAllNfts(response.data);

          let args=[];
          response.data.map((nft) =>{
            args.push(new U64Value(parseInt(nft.nonce)));
          });

          let abiRegistry = AbiRegistry.create(abiFile);
          let abi = new SmartContractAbi(abiRegistry, ["ElrondKnightStaking"]);

          let contract = new SmartContract({ address: new Address(contractAddressStaking), abi: abi });
          const proxy = new ProxyNetworkProvider(network.apiAddress);

          const queryRewards = new Query({
            address: new Address(contractAddressStaking),
            func: new ContractFunction('getNonceCat'),
            args: args
          });

          proxy.queryContract(queryRewards)
            .then(function(queryResponse){
              let endpointDefinition = contract.getEndpoint("getNonceCat");
              let { firstValue }  = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
              let cat={};
              firstValue.items.map((response) => {
                cat[parseInt(response.items[0].value)]=response.items[1].name;
              })
              setRarityCat(cat);

            });


        });


    },[]);


    const searchHandle = () => {
      setFiltre(parseInt(research));
    }

    const changeActiveRarity = (newRarity) => {
      if(newRarity===activeRarity){
        setActiveRarity("");
      }else{
        setActiveRarity(newRarity);
      }
    }

    return (

        // <div className="Home1" Style='min-height:0;'>
        <div className="Home1 collectiblesSec">
            <Container>
                <Row>
                    <h2>Knight gallery</h2>
                </Row>
                <Row>
                    <div className='ContourBlanc'>
                        <Row className='searchLine'>
                            <div className='boxInput'>
                            <input id="text-192" value={research} onChange={(event)=>setResearch(event.target.value)} type="text" placeholder=" #Number" Style='padding-left:5px'></input>
                            </div>
                            <div>
                                <img Style={"cursor : pointer;"} onClick={searchHandle} src={search} alt=''/>
                            </div>
                        </Row>
                        <Row className='rarity'>
                            <div>
                                <h3>Rarity</h3>
                            </div>
                            <div className='rarityButtons'>
                                <div Style={"cursor : pointer;-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;"} onClick={()=>changeActiveRarity("Basic")} className={activeRarity==="Basic" ? 'rarityBTN activeRarity' : 'rarityBTN'}>{Basic}</div>
                                <div Style={"cursor : pointer;-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;"} onClick={()=>changeActiveRarity("Rare")} className={activeRarity==="Rare" ? 'rarityBTN activeRarity' : 'rarityBTN'}>{Rare}</div>
                                <div Style={"cursor : pointer;-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;"} onClick={()=>changeActiveRarity("Exclusive")} className={activeRarity==="Exclusive" ? 'rarityBTN activeRarity' : 'rarityBTN'}>{Exclusive}</div>
                            </div>
                        </Row>
                        <div className='knightStaking Stake1'>
                            <Col>

                                <Row className='text-center'>

                                    {
                                      allNfts.map((nft) => (
                                        ((filtre==undefined ||  isNaN(filtre)) && (activeRarity==="" || (rarityCat[parseInt(nft.nonce)]===activeRarity || (activeRarity==="Basic" && rarityCat[parseInt(nft.nonce)]===undefined))))?
                                          (<GalleryCards
                                            image1={nft.url}
                                            name={nft.name}
                                            rarity={rarityCat[parseInt(nft.nonce)]}
                                          />) :

                                        filtre===parseInt(nft.name.split('#')[1]) && (activeRarity==="" || (rarityCat[parseInt(nft.nonce)]===activeRarity || (activeRarity==="Basic" && rarityCat[parseInt(nft.nonce)]===undefined))) &&
                                          <GalleryCards
                                            image1={nft.url}
                                            name={nft.name}
                                            rarity={rarityCat[parseInt(nft.nonce)]}
                                          />
                                      ))
                                    }

                                    <Col></Col>
                                </Row>
                            </Col>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>

    );
}

export default Gallery;
