import {Card, Col, Row} from 'react-bootstrap'
import ButtonBlue from '../Components/ButtonBlue';
import ButtonGrey from '../Components/ButtonGrey';

import "../Design/StakingCards.css"
import "../Design/Home.css"
import "../Design/Collectibles.css"

function GalleryCards(props) {
    return (

        <>
        <Col xs={12} sm={6} md={4}>
            <div className="StakeKnights">
                <Card className="KnightCards">
                    <Card.Img variant="top" src={props.image1}/>


                    <Card.Text>
                        <div className='rewardKNT'>
                            <p Style={'padding-right: 0; width: auto; height: 40px;'}>{props.name}</p>
                        </div>
                    </Card.Text>

                    <div className='buttonsSkate'>

                        <div className='categories'>{props.rarity===undefined ? "Basic" : props.rarity}</div>

                    </div>
                </Card>
            </div>
        </Col>
        </>

    )
}
export default GalleryCards;
