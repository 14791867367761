import {Card, Col, Row} from 'react-bootstrap'
import ButtonBlue from '../Components/ButtonBlue';
import ButtonGrey from '../Components/ButtonGrey';

import "../Design/StakingCards.css"
import "../Design/Home.css"
import "../Design/Collectibles.css"

function StakingCard(props) {
    return (

        <>
        <Col xs={12} sm={12} md={4}>
            <div className="StakeKnights">
                <Card className="KnightCards">
                    <Card.Img variant="top" src={props.image1}/>
                    <Card.Text>
                    <p className='specialP'>{props.txt1}</p>
                    <div className='rewardKNT'>
                        <p>Earned rewards: </p>
                        <p>{props.value1}</p>
                        <p>$KNT</p>
                    </div>
                    </Card.Text>
                    <div className='buttonsSkate'>

                        <ButtonBlue onClick={props.onClickClaim}>CLAIM</ButtonBlue>
                        <ButtonGrey onClick={props.onClickUnstake}>UNSTAKE</ButtonGrey>

                    </div>
                </Card>
            </div>
        </Col>
        </>

    )
}
export default StakingCard;
