export const contractAddress =
  process.env.REACT_APP_SMART_CONTRACT_ADRESS;

export const environment =
  process.env.REACT_APP_ENVIRONMENT;

export const idNft =
  process.env.REACT_APP_NFT_ID;

export const contractAddressStaking =
  process.env.REACT_APP_SMART_CONTRACT_ADRESS_STAKING;


export const dAppName = 'Elrond Knights';
