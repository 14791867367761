import {React, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import {DappProvider, DappUI} from '@elrondnetwork/dapp-core';
import { environment } from './config';
import '@elrondnetwork/dapp-core/dist/index.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './Pages/Home';
import Collectibles from './Pages/Collectibles';
import Gallery from './Pages/Gallery';
import Gaming from './Pages/Gaming';
import Admin from './Pages/Admin';
import ErrorPage from './Pages/404';
import NavbarComp from './Components/NavbarComp';
import Footer from './Components/Footer';

import './App.css';

function App() {

  return (
    <div className="App">



      <Router>
        <DappProvider
            environment={environment}
            customNetworkConfig={{ name: 'customConfig', apiTimeout: 6000 }}
        >
          <DappUI.SignTransactionsModals />
          <DappUI.TransactionsToastList />
          <Routes>
            <Route path="/" element={<><NavbarComp/><div className='bgDegrade'/><Home /><Footer/></>}/>
            <Route path="/collectibles" element={<><NavbarComp/><div className='bgDegrade'/><Collectibles /><Footer/></>}/>
            <Route path="/gallery" element={<><NavbarComp/><div className='bgDegrade'/><Gallery /><Footer/></>}/>
            <Route path="/gaming" element={<><NavbarComp/><div className='bgDegrade'/><Gaming /><Footer/></>}/>
            <Route path="/unlock" element={<><NavbarComp/><div className='bgDegrade'/><Home /><Footer/></>}/>
            <Route path="/admin" element={<><NavbarComp/><div className='bgDegrade'/><Admin /><Footer/></>}/>
            <Route path="*" element={<ErrorPage/>}/>
          </Routes>

        </DappProvider>
      </Router>


    </div>
  );
}

export default App;
