import {useRef,useEffect, useState} from 'react';
import {useGetLoginInfo, transactionServices, refreshAccount,useGetAccountInfo,useGetNetworkConfig,getAccount, logout} from "@elrondnetwork/dapp-core";
import { contractAddress } from '../config';
import {Container, Row, Col} from 'react-bootstrap';
import ButtonBlue from '../Components/ButtonBlue';
import PopupConnexion from '../Components/PopupConnexion/PopupConnexion';
import abiFile from "../elrondknight.abi.json";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";

import {
    Address,
    ContractFunction,
    Query,
    U8Value,
    AbiRegistry,
    SmartContractAbi,
    SmartContract,
    ResultsParser
  } from '@elrondnetwork/erdjs';

import '../Design/Admin.css';



function Admin(props) {
    //Variables
    const {isLoggedIn}=useGetLoginInfo();
    const { network } = useGetNetworkConfig();
    const { address } = useGetAccountInfo();
    const { sendTransactions } = transactionServices;

    //State
    const [ownerAddress, setOwnerAddress] = useState();
    const [showPopupLogin, setShowPopupLogin] = useState(false);
    const [nbSale,setNbSale]=useState("1");
    const [sales,setSales]=useState({"1":{"statut":"Close"},"2":{"statut":"Close"},"3":{"statut":"Close"},"4":{},"5":{"statut":"Close"},"6":{"statut":"Close"},"7":{"statut":"Close"},"8":{"statut":"Close"},"9":{"statut":"Close"},"10":{"statut":"Close"},"11":{"statut":"Close"},"12":{"statut":"Close"},"13":{"statut":"Close"}});



    //Effect
    useEffect(() => {
        fetch(network.apiAddress + "/accounts/" + contractAddress ).then(response => response.json())
        .then(response => {
            setOwnerAddress(response.ownerAddress);
        })
        .catch(error => alert("Erreur : " + error));
    },[]);

    useEffect(() => {
        let abiRegistry = AbiRegistry.create(abiFile);
        let abi = new SmartContractAbi(abiRegistry, ["ElrondKnight"]);

        let contract = new SmartContract({ address: new Address(contractAddress), abi: abi });
        const proxy = new ProxyNetworkProvider(network.apiAddress);

        const queryCurrentSale = new Query({
            address: new Address(contractAddress),
            func: new ContractFunction('current_sale'),
            args: []
          });
        proxy.queryContract(queryCurrentSale)
        .then((queryResponse) => {
            let endpointDefinition = contract.getEndpoint("current_sale");
            let { firstValue } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
            setNbSale( parseInt(firstValue.value));
        })


        for (let i = 1; i <= 13 ; i++) {
            let query = new Query({
                address: new Address(contractAddress),
                func: new ContractFunction('sales'),
                args: [new U8Value(i)]
            });
            proxy.queryContract(query)
            .then((queryResponse) => {
                let endpointDefinition = contract.getEndpoint("sales");
                let { firstValue } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
                let newSales = sales;
                let newSale={
                    "price":parseInt(firstValue.fields[4].value.value),
                    "statut":firstValue.fields[3].value.name,
                    "uri_image":firstValue.fields[1].value.value,
                    "uri_metadata":firstValue.fields[0].value.value
                };
                newSales[i.toString()] = newSale;
                setSales({...sales,newSales});


            })

        }

    },[]);

    //Function
    const disconnect = () => {
        logout();
    }

    const handleLoginClose = () => {
        setShowPopupLogin(false);
    }

    const handleShowPopup = () => {
        setShowPopupLogin(true);
    }

    const changeStatePrice = (saleNb,newPrice) => {
        let newState = Object.assign({}, sales);
        newState[saleNb]["price"]=newPrice;
        setSales(newState);
    }

    const changeStateUriMeta = (saleNb,newUri) => {
        let newState = Object.assign({}, sales);
        newState[saleNb]["uri_metadata"]=newUri;
        setSales(newState);
    }

    const changeStateUriImage = (saleNb,newUri) => {
        let newState = Object.assign({}, sales);
        newState[saleNb]["uri_image"]=newUri;
        setSales(newState);
    }

    const changePriceTransaction = async(nb) => {
        let argsNb=nb.toString(16);
        let newPriceArgs=sales[nb]["price"];
        newPriceArgs=parseInt(newPriceArgs).toString(16);
        if(argsNb.length%2===1){
            argsNb="0"+argsNb;
        }
        if(newPriceArgs.length%2===1){
            newPriceArgs="0"+newPriceArgs;
        }
        const changePriceTransaction = {
            value: "0",
            data: 'changePrice@'+argsNb+"@"+newPriceArgs,
            receiver: contractAddress,
            gasLimit: 600_000_000,
          };
        await refreshAccount();
        const { sessionId, error } = await sendTransactions({
            transactions: changePriceTransaction,
            transactionsDisplayInfo:{
                processingMessage: 'Processing transaction',
                errorMessage: 'An error has occured during transaction',
                successMessage: 'Transaction success'
            },
            redirectAfterSign: false
        });
    }

    const changeUriTransaction = async (nb) => {
        let argsNb=nb.toString(16);
        const newUriMeta = new Buffer(sales[nb]["uri_metadata"]).toString('hex');
        const newUriImage = new Buffer(sales[nb]["uri_image"]).toString('hex');

        if(argsNb.length%2===1){
            argsNb="0"+argsNb;
        }

        const changeUriTransaction = {
            value: "0",
            data: 'changeUri@'+argsNb+"@"+newUriMeta+"@"+newUriImage,
            receiver: contractAddress,
            gasLimit: 600_000_000,
          };
        await refreshAccount();
        const { sessionId, error } = await sendTransactions({
            transactions: changeUriTransaction,
            transactionsDisplayInfo:{
                processingMessage: 'Processing transaction',
                errorMessage: 'An error has occured during transaction',
                successMessage: 'Transaction success'
            },
            redirectAfterSign: false
        });
    }

    const openSale = async (nb) => {


        const changeUriTransaction = {
            value: "0",
            data: 'openCurrentSale',
            receiver: contractAddress,
            gasLimit: 600_000_000,
          };
        await refreshAccount();
        const { sessionId, error } = await sendTransactions({
            transactions: changeUriTransaction,
            transactionsDisplayInfo:{
                processingMessage: 'Processing transaction',
                errorMessage: 'An error has occured during transaction',
                successMessage: 'Transaction success'
            },
            redirectAfterSign: false
        });
    }

    const closeSale = async () => {


        const changeUriTransaction = {
            value: "0",
            data: 'closeCurrentSale',
            receiver: contractAddress,
            gasLimit: 600_000_000,
          };
        await refreshAccount();
        const { sessionId, error } = await sendTransactions({
            transactions: changeUriTransaction,
            transactionsDisplayInfo:{
                processingMessage: 'Processing transaction',
                errorMessage: 'An error has occured during transaction',
                successMessage: 'Transaction success'
            },
            redirectAfterSign: false
        });
    }


    const changeSale = async () => {

        const changeUriTransaction = {
            value: "0",
            data: 'changeSale',
            receiver: contractAddress,
            gasLimit: 600_000_000,
          };
        await refreshAccount();
        const { sessionId, error } = await sendTransactions({
            transactions: changeUriTransaction,
            transactionsDisplayInfo:{
                processingMessage: 'Processing transaction',
                errorMessage: 'An error has occured during transaction',
                successMessage: 'Transaction success'
            },
            redirectAfterSign: false
        });
    }


    //Render
    let render;
    if (isLoggedIn && address!==ownerAddress){
        render = <div className="content-admin">
            <div>You have to be connected as the contract owner</div>
            <ButtonBlue onClick={disconnect}>Logout</ButtonBlue>
        </div>
    }else if (!isLoggedIn){
        render = <div className="content-admin">
            <div>You have to be connected as the contract owner</div>
            <ButtonBlue onClick={handleShowPopup}>Connect</ButtonBlue>
        </div>
    }else{
        let items = [];
        for (const i in sales){
            if(i>=nbSale){
                items.push(
                    <>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <input className='inputAdmin' type="number" onChange={(e) => changeStatePrice(i,e.target.value)} value={sales[i]["price"]}/>
                        </Col>
                        <Col sm={12} md={4} lg={4}></Col>
                        <Col sm={12} md={4} lg={4}>
                            <ButtonBlue onClick={() => changePriceTransaction(i)}>Change price for sale {i}</ButtonBlue>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <label>Uri metadata</label><input className='inputAdmin' onChange={(e) => changeStateUriMeta(i,e.target.value)} value={sales[i]["uri_metadata"]}/>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <label>Uri image</label><input className='inputAdmin' onChange={(e) => changeStateUriImage(i,e.target.value)} value={sales[i]["uri_image"]}/>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <ButtonBlue onClick={() => changeUriTransaction(i)}>Change URIs for sale {i}</ButtonBlue>
                        </Col>
                    </Row>
                    </>)
            }

        }
        render = <div className="content-admin">
        <Container>
        <div className='admin-title'>Current sale {nbSale}</div>
        <div className='admin-title'>To change URI, please respect the following form : "CID/". Something like : "Qmahjkcgnqzkjcg/"</div>
        {sales[nbSale]["statut"]==="Close" && <ButtonBlue onClick={openSale}>Open sale</ButtonBlue>}
        {sales[nbSale]["statut"]==="Open" && <ButtonBlue onClick={closeSale}>Close sale</ButtonBlue>}
        {sales[nbSale]["statut"]==="SoldOut" && <ButtonBlue onClick={changeSale}>Close sale</ButtonBlue>}
        {items}
        </Container>
    </div>
    }

    return(
        <div className="Admin">
            {(showPopupLogin && !isLoggedIn) && <PopupConnexion handleClose={handleLoginClose}/>}
            {render}
        </div>
    )
}

export default Admin;
