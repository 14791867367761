import {Container, Row, Col} from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import CardKnight from "../Components/KnightsCards";
import {useRef,useEffect, useState} from 'react';
import ButtonBlue from '../Components/ButtonBlue';
import ButtonLeft from '../Components/ButtonBlueLeft';
import TeamStandartSection from '../Components/TeamStandard';
import TeamCarousel from '../Components/TeamCarousel';
import LottieCollection from '../Components/Lottie/collection';
import LottieDAO from '../Components/Lottie/DAO';
import Lottiegaming from '../Components/Lottie/gaming';
import {
  Link
} from "react-router-dom";

import PopupConnexion from '../Components/PopupConnexion/PopupConnexion';
import {useGetLoginInfo, transactionServices, refreshAccount,useGetNetworkConfig, useGetAccountInfo, logout} from "@elrondnetwork/dapp-core";
import abiFile from "../elrondknight.abi.json";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import {
    Address,
    ContractFunction,
    Query,
    U8Value,
    AbiRegistry,
    SmartContractAbi,
    SmartContract,
    ResultsParser
  } from '@elrondnetwork/erdjs';

import '../Design/Home.css';

import Jedi from '../Assets/Jedi.jpg';
import EKLogo from '../Assets/Elrond_Knights_logo_01.svg';
import ElrondLogo from '../Assets/Logo_Elrond.svg'
import Sword from '../Assets/Sword.svg';
import caroussel1 from '../Assets/caroussel_1.jpg';
import caroussel2 from '../Assets/caroussel_2.png';
import caroussel3 from '../Assets/caroussel_3.png';
import caroussel4 from '../Assets/caroussel_4.png';
import caroussel5 from '../Assets/caroussel_5.png';
import caroussel6 from '../Assets/caroussel_6.png';
import { contractAddress } from '../config';
import { propTypes } from 'react-bootstrap/esm/Image';

function Home(props) {
    let nb_max ;
    let sold ;
    let statut ;
    const [isWhitelist,setIsWhitelist] = useState(false)

    const { network } = useGetNetworkConfig();

    const {address}= useGetAccountInfo();

    const [nbKnights,setNbKnights]=useState(1);

    const [showPopupLogin,setShowPopupLogin]=useState(false);

    const [nbSale,setNbSale]=useState(0);

    const [priceSale,setPriceSale]=useState(0);

    const { sendTransactions } = transactionServices;

    const handleLogin = () => {
      setShowPopupLogin(true);
    }

    const handleLoginClose = () => {
      setShowPopupLogin(false);
    }
    const {isLoggedIn}=useGetLoginInfo();


    const changeNumberKnights = (value) => {
        if (isWhitelist){
            let lastTwoDigit=value%100;
            if(isNaN(lastTwoDigit)){
                setNbKnights(0);
            }
            else if(lastTwoDigit>10){
                setNbKnights(10);
            }
            else{
                setNbKnights(lastTwoDigit);
            }
        }else{
            let lastDigit=value%10;
            if(isNaN(lastDigit)){
                setNbKnights(0);
            }
            else if(lastDigit>5){
                setNbKnights(5);
            }
            else{
                setNbKnights(lastDigit);
            }
        }


    }

    const mint = async () => {
        let totalPrice = priceSale * nbKnights;
        let argsNb=nbKnights.toString(16);
        if(argsNb.length%2===1){
            argsNb="0"+argsNb;
        }
        const mintTransaction = {
            value: totalPrice.toString(),
            data: 'mintNft@'+argsNb,
            receiver: contractAddress,
            gasLimit: 600_000_000,
          };
        await refreshAccount();
        const { sessionId, error } = await sendTransactions({
            transactions: mintTransaction,
            transactionsDisplayInfo:{
                processingMessage: 'Processing transaction - Your knights are putting their armor on',
                errorMessage: 'An error has occured during transaction',
                successMessage: 'Your knights are ready'
            },
            redirectAfterSign: false
        });
    }

    useEffect(async () => {

        let abiRegistry = AbiRegistry.create(abiFile);
        let abi = new SmartContractAbi(abiRegistry, ["ElrondKnight"]);

        let contract = new SmartContract({ address: new Address(contractAddress), abi: abi });
        const proxy = new ProxyNetworkProvider(network.apiAddress);



        const queryCurrentSale = new Query({
          address: new Address(contractAddress),
          func: new ContractFunction('current_sale'),
          args: []
        });
        let queryResponse = await proxy.queryContract(queryCurrentSale);
        let endpointDefinition = contract.getEndpoint("current_sale");
        let { firstValue } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
        setNbSale(firstValue);

        const queryWhitelist = new Query({
            address: new Address(contractAddress),
            func: new ContractFunction('whitelist'),
            args: []
        });
        queryResponse = await proxy.queryContract(queryWhitelist);
        endpointDefinition = contract.getEndpoint("whitelist");
        let response = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

        let addresses = response.firstValue.items;
        for (let whitelistAddress in addresses) {
            if(addresses[whitelistAddress].value === address){
                setIsWhitelist(true);
            }
        }


    },[]);

    useEffect(async () => {

        let abiRegistry = AbiRegistry.create(abiFile);
        let abi = new SmartContractAbi(abiRegistry, ["ElrondKnight"]);

        let contract = new SmartContract({ address: new Address(contractAddress), abi: abi });
        const proxy = new ProxyNetworkProvider(network.apiAddress);

        const queryCurrentSale = new Query({
          address: new Address(contractAddress),
          func: new ContractFunction('sales'),
          args: [new U8Value(nbSale)]
        });

        let queryResponse = await proxy.queryContract(queryCurrentSale);
        let endpointDefinition = contract.getEndpoint("sales");
        let { firstValue }  = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
        if (firstValue!==null){
            nb_max = firstValue.fields[2].value.value;
            setPriceSale(parseInt(firstValue.fields[4].value.value));
            sold = firstValue.fields[5].value.value;
            statut = firstValue.fields[3].value.name;
        }

    });



// Function IS MOBILE

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('resize',swordWidth);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            window.removeEventListener('resize',swordWidth);
            }
    }, []);

const isMobile = width <= 768;

const isMobile2 = width <= 500;

const SwordLeft = useRef();
const SwordRight = useRef();
const SwordAnimation = useRef();

const swordWidth = () => {
    let value = (1+(1-(window.innerWidth/1000)))*120;
    // window.alert(value)
    SwordAnimation.current.style.width = (value) + '%';
}


const scrollingImage = () => {

    if (window.scrollY >= 150 && window.scrollY <= 500 && !isMobile2) {
      let value = window.scrollY -400 ;
      SwordLeft.current.style.left = (value) * 0.6 +'px';
      SwordRight.current.style.right = (value) * 0.6 +'px';
      SwordLeft.current.style.opacity = (value + 250) * 0.002 +0.2;
      SwordRight.current.style.opacity = (value + 250) * 0.002 +0.2;

    //   && !isMobile

    }
    else if (window.scrollY >= 500 && !isMobile2) {
        let value = 100 ;
        SwordLeft.current.style.left = (value) * 0.6 +'px';
        SwordRight.current.style.right = (value) * 0.6 +'px';
        SwordLeft.current.style.opacity = (1);
        SwordRight.current.style.opacity = (1);

      }
    //   if (window.scrollY >= 200 && isMobile)
    else if (window.scrollY >= 20 && window.scrollY <= 250 && isMobile2) {
        let value = window.scrollY -250 ;
        SwordLeft.current.style.left = (value + 300) * 0.6 +'px';
        SwordRight.current.style.right = (value + 300) * 0.6 +'px';
        SwordLeft.current.style.opacity = (value + 250) * 0.002 +0.2;
        SwordRight.current.style.opacity = (value + 250) * 0.002 +0.2;
        SwordLeft.current.style.width = 238.05 +'px';
        SwordRight.current.style.width = 238.05 +'px';
        SwordLeft.current.style.height = 145.6 +'px';
        SwordRight.current.style.height = 145.6 +'px';
    }

    else if (window.scrollY >= 400 && isMobile2) {
        let value = 0 ;
        // SwordLeft.current.style.left = (value) * 0.6 +'px';
        // SwordRight.current.style.right = (value) * 0.6 +'px';
        // SwordLeft.current.style.opacity = (1);
        // SwordRight.current.style.opacity = (1);
        // SwordLeft.current.style.width = 238.05 +'px';
        // SwordRight.current.style.width = 238.05 +'px';
        // SwordLeft.current.style.height = 145.6 +'px';
        // SwordRight.current.style.height = 145.6 +'px';
      }

    else {

    }
  }

  useEffect(()=> {
    window.addEventListener('scroll',scrollingImage);
  },[])

// Meet the Knights Carousel content

    const dataCardsKnights = [
        {
            image: caroussel1,
        },
        {
            image: caroussel2,
        },
        {
            image: caroussel3,
        },
        {
            image: caroussel4,
        },
        {
            image: caroussel5,
        },
        {
            image: caroussel6,
        },
    ]

// Number of cards dislayed for Carousel Meet the Knights

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1200, min: 790 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 790, min: 0 },
          items: 1
        }
      };


    return (
      <div className="Home1">
        {(showPopupLogin && !isLoggedIn) && <PopupConnexion handleClose={handleLoginClose}/>}

{/* Première section */}
{/* 1.1 */}

        <section className='container container1'>
            <div className='introSC'>
                <Container>
                    <Row>
                        <Col className='imgCT1'>
                        <div><img src={EKLogo} alt=""></img></div>
                        </Col>
                    </Row>
                    <div className='swordsAnimation' ref={SwordAnimation}>
                        <img src={Sword} ref={SwordLeft} alt='' className='sword swordLeft'/>
                        <img src={Sword} ref={SwordRight} alt='' className='sword swordRight'/>
                    </div>
                    <Row>
                        <Col>
                        <h2 className='H2Bringing'>Bringing the ancient rivalry between light and darkness on the Elrond blockchain</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <p>Choose your side wisely and fight for the Brotherhood in a timeless Roguelike experience. </p>
                        </Col>
                    </Row>
                </Container>
            </div>

{/* 1.2 */}
            <div className='focusesSC'>
                <Container>

                    <Row>
                        <h2>Our major focuses</h2>
                    </Row>

                    <Row className='justify-content-center'>
                        <Col className='cards' sm={12} md={12} lg={4}>
                            <Col className='imgCT'>
                                <Lottiegaming/>

                            </Col>
                            <h3>Gaming</h3>
                            <p>We want to take advantage of the potential of the blockchain
                                technology to create a Roguelike Play to Earn based on the
                                rivalry between Light and Darkness.
                            </p>

                            <Link to={"./gaming"} style={{ textDecoration: 'none', color:"white" }}>
                              <ButtonBlue>
                                  Take a look
                              </ButtonBlue>
                            </Link>
                        </Col>

                        <Col className='cards' sm={12} md={12} lg={4}>
                            <Col className='imgCT'>
                                <LottieCollection/>
                            </Col>
                            <h3>Classic collection</h3>
                            <p>A collection of pixel art Knights inspired
                            by different universes and eras. The Knights
                            play a major role in the DAO membership and
                            Gaming plans.
                            </p>


                            <Link to={"./gallery"} style={{ textDecoration: 'none', color:"white" }}>
                              <ButtonBlue >
                                  MEET THE KNIGHTS
                              </ButtonBlue>
                            </Link>

                        </Col>

                        <Col className='cards' sm={12} md={12} lg={4}>
                            <Col className='imgCT'>
                                <LottieDAO/>
                            </Col>
                            <h3>DAO</h3>
                            <p>Our brotherhood members (NFT holders), can
                                benefit of token governance and take part
                                in the developement of the project.
                            </p>
                            <a href={"https://leather-ear-66e.notion.site/KNT-Tokenomy-V1-438521c3987745deb411eb92ad8542ad"} target="_blank" style={{ textDecoration: 'none', color:"white" }}>
                              <ButtonBlue>
                                  DISCOVER MORE
                              </ButtonBlue>
                            </a>
                        </Col>
                    </Row>

                </Container>
            </div>

{/* 1.3 */}
            <div className='mintSC'>
            <Container>

                <Row className='txtJustify'>
                    <h2>Mint a Knight and join the brotherhood</h2>
                </Row>

                <Row>
                    <Col className='imgCT2' sm={12} md={12} lg={6}>
                        <img src={Jedi} alt=""></img>
                    </Col>

                    <Col className='txtJustify' sm={12} md={12} lg={6}>
                    <h3 Style="font-family:'Nunito-SemiBold', sans-serif;">the KNIGHTS are READY TO BATTLE!</h3>
                    <p>The Classic Collection is made out of generative and semi-generative NFTs,
                        inspired on different eras and universes. As we decided to use a
                        different way of creating this collection, the result is 4000 knights
                         based on 16 different eras: 50% basic knights, 46% rare knights
                         and 4% exclusives.
                    </p>
                    <p>The next form will guide you through the minting process.
                        You will need a Maiar access or an Elrond Wallet adress to complete the purchase.
                    </p>
                    <p Style="font-family:'Nunito-SemiBold', sans-serif;">Current minting price :</p>
                    <div className='ElrondPrice'>
                        <img src={ElrondLogo} alt=''/>
                        <p className='mintingPrice'>{Math.round(priceSale * nbKnights * (10**-18) * 100 )/100}</p>
                    </div>
                    <p Style={"margin:0; font-family:'Nunito-SemiBold', sans-serif;"}>HOW MANY KNIGHTS WOULD YOU LIKE TO RECRUTE?</p>

                    <div className='formBox'>
                        <input onChange={(e)=>changeNumberKnights(e.target.value)} value={nbKnights} className='formCSS'></input>
                    </div>
                    <div className='buttonBox'>
                        {
                            !isLoggedIn ?
                                <ButtonLeft onClick={handleLogin}>
                                    START MINTING
                                </ButtonLeft>
                            :
                                <ButtonLeft onClick={mint}>
                                    MINT
                                </ButtonLeft>
                        }

                    </div>

                    </Col>
                </Row>

                </Container>
            </div>
        </section>

{/* Deuxieme section */}

        <section className='containerNP bgColor1'>
            <Container Style="height: 600px;">

                <Row>
                    <h2>Meet the Knights</h2>
                </Row>
                <Row>
                    {/* CAROUSSEL */}

                    {/* Cards Carousel */}

                    <div className='cardsCarou' id="image">
                        <Carousel responsive={responsive} swipeable={true} draggable={true} infinite={true}
                        itemClass="carousel-item-center" autoPlaySpeed={6000} showDots={true}>
                        {dataCardsKnights.map(data=>{
                            return (
                            <CardKnight
                                image={data.image}
                            />
                            )
                        })
                        }
                        </Carousel>
                    </div>

                </Row>
                <Row className='buttonSection'>
                    <Link to="/gallery" style={{ textDecoration: 'none', color:"white" }}>
                      <ButtonBlue>
                          Show me more!
                      </ButtonBlue>
                    </Link>
                </Row>

            </Container>
        </section>

{/* Troisieme section */}

        <section className='containerClass' id ="video-game">
            <div className='prepareSC'>
                <Container>

                    <Row>
                        <h2>Prepare your weapon! </h2>
                    </Row>
                    <Row>
                        <p>A roguelike adventure is now in developement. Knights of both sides,
                        Light and Darkness, will have to explore the dungeons, defeat multiple
                        enemies and build a strong strategy if they want to achieve glory for
                        their community.
                        </p>
                    </Row>
                    <Row>
                        <p>
                        Our aim is to create a roguelike experience and enhance it with the
                        blockchain technology.
                        </p>
                    </Row>
                    <Row>
                        <p>
                        This videogame will match the graphic design art of the classic collection.
                        As the pixel-art allows high quality designs, we can redirect our creative
                        effort and your contribution to the abilities that matter: the Web3. All
                        the quests, storytelling and the indie game roadmap will be decided by the
                        Executive DAO membership to continue the community driven path.
                        </p>
                    </Row>
                    <Row>
                        <p>
                        If you want to contribute or just stay tuned about the project, follow us in
                        our social media. The team will be glad to answer your questions and hear
                        about your opinions and ideas.
                        </p>
                    </Row>

                </Container>
            </div>
        </section>

{/* Quatrieme section */}

        <section className='containerNP bgColor2' id="about-us">
            <Container>

                <Row>
                    <h2>The team</h2>
                </Row>


                {!isMobile ? (
                    <TeamStandartSection/>

                ): (
                    <div className='TeamCarousel'>
                    <TeamCarousel/>
                    </div>

                )}




        {/* ATTENTION !
        REMPLACER PAR CAROUSSEL EN VERSION MOBILE */}



            </Container>
        </section>
      </div>
    );
  }

  export default Home;
