import '../Design/404.css'

import Rouage from '../Assets/Rouage.png';
import Rouage2 from '../Assets/Rouage2.png';
import EKLogo from '../Assets/Elrond_Knights_logo_01.svg';

function Gaming() {

    return (

        <>
        <div className='ErrorPageCSS'>
            <section className='container'>
                <img className='LogoKnights' src={EKLogo} alt=''></img>
                <h3>Game in development : <br/>Our knights are currenting building castles and planting trees</h3>
                <div className='ContentRouage'>
                    <img className='R2' src={Rouage2} alt=''></img>
                    <img className='R1' src={Rouage} alt=''></img>
                </div>
            </section>
        </div>
        </>
    );
}
    
export default Gaming;