import {Container,Navbar, Nav, Offcanvas, Row, Col} from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import {useGetLoginInfo,logout} from "@elrondnetwork/dapp-core";

// import ButtonW1 from './ButtonW1';
import '../Design/NavbarComp.css';
import '../Design/Home.css';

import WhiteLogo from '../Assets/Elrond_Knights_logo_01.svg';
import twitter from '../Assets/twitter.svg';
import discord from '../Assets/discord.svg';
import telegram from '../Assets/telegram.svg';

function NavbarComp(props) {

  const [navbarScroll, setNavbarScroll] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showNav, setShowNav] = useState(true);
  const {isLoggedIn}=useGetLoginInfo();

  // const changeBackground = () => {
  //   if(window.scrollY >= 100) {
  //     setNavbarScroll(true);
  //   }
  //   else {
  //     setNavbarScroll(false);
  //   }
  // }

  // window.addEventListener('scroll',changeBackground);

  // const controlNavbar = () => {
  //   if(window.scrollY > 100) {
  //     setShowNav(true);
  //   }
  //   else {
  //     setShowNav(false);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll',controlNavbar)
  //   return () =>
  //   window.removeEventListener('scroll',controlNavbar)
  // }, [])

  function MenuCache(e) {

    e.preventDefault();
    setShow(true);
  }

// Going Up

  const prevScrollY = useRef(0);
  

  const [goingUp, setGoingUp] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && window.scrollY > 100 && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

return (
  <div className='NavbarControl'>

    <Offcanvas show={show} onHide={handleClose} placement="end" >
        <div className="offCanvas1">
        <Container>
          <Row>
            <Col xs={8} sm={8} md={8}></Col>
            <Col xs={4} sm={4} md={4}>
              <Offcanvas.Header closeButton closeVariant="white" className='closeRight'>
                <span type="button" onClick={handleClose}>
                  Close
                </span>
              </Offcanvas.Header>
            </Col>
          </Row>
        </Container>
        <Offcanvas.Body>
          <Container>
            <Col md={12}>

              <Row className='rowOffcanvas'>
                <LinkContainer to="/">
                    <Nav.Link className='underlined2' Style={"font-weight:600"} href="#home" onClick={handleClose}>
                    <span Style={"color:white"}>The project</span>
                    </Nav.Link>
                  </LinkContainer>
              </Row>
              <Row className='rowOffcanvas'>
                <LinkContainer to="/gallery">
                  <Nav.Link className='underlined2' Style={"font-weight:600"} href="#NFT" onClick={handleClose}>
                      <span Style={"color:white"}>NFT Collectibles</span>
                  </Nav.Link>
                </LinkContainer>
              </Row>
              <Row className='rowOffcanvas'>

                <Nav.Link className='underlined2' Style={"font-weight:600"} href="/#video-game" onClick={handleClose}>
                <span Style={"color:white"}>The videogame</span>
                </Nav.Link>

              </Row>

              <Row className='rowOffcanvas'>

                  <Nav.Link className='underlined2' Style={"font-weight:600"} href="/#about-us">

                  <span Style={"color:white"}>About us</span>
                  </Nav.Link>
              </Row>

              <Row className='rowOffcanvas'>
                <LinkContainer to="/collectibles">
                  <Nav.Link className='underlined2' Style={"font-weight:600"} href="#nft-staking" onClick={handleClose}>
                  <span Style={"color:white"}>NFT Staking</span>
                  </Nav.Link>
                </LinkContainer>
              </Row>

              <Row className='rowOffcanvas'>
                <hr className='hrOffcanvas'></hr>
              </Row>

              <Row>
                <div className='txtNav'>
                    <h3>Bringing the ancient rivalry between light and darkness on the Elrond blockchain</h3>
                </div>
              </Row>

              <Row>
                <div className='iconNav'>
                  <a href="https://twitter.com/elrondknights" target="_blank"><img src={twitter} alt=""></img></a>
                  <a href="https://discord.com/invite/2hghVGqdBv" target="_blank"><img src={discord} alt=""></img></a>
                </div>
              </Row>

              <Row>
                <div className='txtNav'>
                    <p>Elrond Knights 2022</p>
                </div>
              </Row>

            </Col>
          </Container>

        </Offcanvas.Body>
        </div>
      </Offcanvas>

  <Navbar bg={!goingUp ? ("hid") : ("black") } variant="dark" expand="lg" fixed="top" expanded={false}>
    <Container>
      

      {/* <Navbar.Brand href="#">
        <img
          alt=""
          src={WhiteLogo}
          height="30"
          className="d-inline-block align-middle"
        />
      </Navbar.Brand> */}
      <Navbar.Toggle Style={"box-shadow:none"} onClick={MenuCache} aria-controls="offcanvasNavbar" />
      {
        isLoggedIn &&
        <Nav Style={"cursor:pointer;"} className="logout-mobile" onClick={()=>logout()}>
          Logout
        </Nav>
      }
      <Navbar.Collapse id="offcanvasNavbar">
        <Nav className="justify-content-center flex-grow-1 px-3 py-2" >
          <LinkContainer to="/">
            <Nav.Link className='underlined ' href="#home" Style={'color:white'}>The project</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/gallery">
          <Nav.Link className='underlinedB' href="#NFT" Style={'color:white'}>NFT collectibles</Nav.Link>
          </LinkContainer>

            <Nav.Link className='underlinedB' href="/#video-game" Style={'color:white'}>The videogame</Nav.Link>


            <Nav.Link className='underlinedB' href="/#about-us" Style={'color:white'}>About us</Nav.Link>

          <LinkContainer to="/collectibles">
            <Nav.Link className='underlinedB' href="#nft-staking" Style={'color:white'}>NFT staking</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
      <Nav Style={"position: absolute;justify-content: end;right: 20px;"}>
      <div className='iconNav nav justify-content-end'>
        <a href="https://twitter.com/elrondknights" target="_blank"><img src={twitter} alt=""></img></a>
        <a href="https://discord.com/invite/2hghVGqdBv" target="_blank"><img src={discord} alt=""></img></a>
      </div>
      </Nav>
    </Container>
  </Navbar>
</div>
)
}

export default NavbarComp;
