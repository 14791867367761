import '../Design/ButtonBlue.css';


function ButtonBlue(props) {

    
    return (
        <div className="button1 buttonCenter" onClick={props.onClick}>
            <span className="buttonBlue"> {props.children}
            </span>
            <div className="hoverEffect"></div> 
        </div>
    );
}
export default ButtonBlue;