import {Container, Row, Col} from 'react-bootstrap'

import Jedi from '../Assets/Jedi.jpg';

import "../Design/Home.css"

import malak from '../Assets/team_malak.jpg';
import coconite from '../Assets/team_coconite.jpg';
import remi from '../Assets/team_remi.jpg';
import brooks from '../Assets/team_brooks.jpg';

function TeamStandartSection (props) {

    const dataCardsTeam = [


      {
          imageTeam: coconite,
          TitreTeam:'Coral. G',
          Pseudo:'Coconite',
          TxtTeam: 'Co-founder & Creative Director',
          TxtTeam2:'Artistic director since 2017 and In charge of the aesthetics and the global concept of Elrond Knights, from pixel art to game experience and production.',
      },
      {
          imageTeam: malak,
          TitreTeam:'Tristan. M',
          Pseudo:'Dark Malak',
          TxtTeam: 'Co-founder & Marketing Director',
          TxtTeam2:'Craftsman and entrepreneur since 2016, Blockchain enthusiast since 2012. In charge of Elrond knight\'s business development and Blockchain strategy.',
      },
      {
          imageTeam: remi,
          TitreTeam:'Rémi. L',
          Pseudo:'Big Mire',
          TxtTeam: 'Developer & Engineer Web3',
          TxtTeam2:'Engineer, smart-contract developer and Web3 enthusiast. In charge of conception and development of the platform and smart-contracts. ',
      },
      {
          imageTeam: brooks,
          TitreTeam:'Brooks',
          Pseudo:'Brooks',
          TxtTeam: 'Community Admin',
          TxtTeam2:'Mechanical engineer and blockchain enthusiast, I\'m the high-flying community admin of the brotherhood.',
      }

     ]

    return (
        <Row>
          {dataCardsTeam.map((data) =>
            <Col sm={12} md={6} lg={3} Style="padding: 30px;">
                <Row className='imgCT3'>
                    <Col className='imgCT3'>
                        <img src={data.imageTeam} alt=""></img>
                    </Col>
                </Row>
                <Row>
                    <h3>{data.TitreTeam}</h3>
                </Row>
                <Row>
                    <p>{data.Pseudo}</p>
                </Row>
                <Row>
                    <p className='pCaps'>{data.TxtTeam}</p>
                </Row>
                <Row>
                    <p>{data.TxtTeam2}</p>
                </Row>
            </Col>
          )

          }

        </Row>

    )
}
export default TeamStandartSection;
