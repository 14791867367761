import {Container, Row, Col} from 'react-bootstrap';

import '../Design/Footer.css';

import EKLogo from '../Assets/Elrond_Knights_logo_01.svg';
import twitter from '../Assets/twitter.svg';
import discord from '../Assets/discord.svg';
import telegram from '../Assets/telegram.svg';


function Footer() {

    return (

        <div className='footer'>
            <section className='container'>
            <div className='imgFooter'>
                <img src={EKLogo} alt=""></img>
            </div>
            <div className='iconFooter'>
                <a href="https://twitter.com/elrondknights" target="_blank"><img src={twitter} alt=""></img></a>
                <a href="https://discord.com/invite/2hghVGqdBv" target="_blank"><img src={discord} alt=""></img></a>

            </div>
            <div className='txtFooter'>
                <p>Elrond Knights 2022</p>
            </div>
            </section>
        </div>

    );
}

export default Footer;
