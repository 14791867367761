import {useRef,useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import ButtonBlue from '../Components/ButtonBlue';
import ButtonGrey from '../Components/ButtonGrey';
import StakingCard from '../Components/StakingCards';
import UnStakedCard from '../Components/UnStakedCards';
import PopupConnexion from '../Components/PopupConnexion/PopupConnexion';
import axios from 'axios';
import {useGetLoginInfo, refreshAccount, transactionServices,useGetNetworkConfig, logout,useGetAccountInfo} from "@elrondnetwork/dapp-core";
import { getNfts ,getNft } from '../apiEndpoints';
import { idNft } from '../config';
import {
    Address,
    ContractFunction,
    Query,
    U8Value,
    AbiRegistry,
    SmartContractAbi,
    SmartContract,
    ResultsParser,
    AddressValue
  } from '@elrondnetwork/erdjs';

import abiFile from "../elrondknightstacking.abi.json";

import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";

import { contractAddressStaking } from '../config';



import '../Design/Home.css';
import '../Design/Collectibles.css';

import Jedi from '../Assets/Jedi.jpg';


function Collectibles(props) {

    const { network } = useGetNetworkConfig();
    const { address } = useGetAccountInfo();
    const { sendTransactions,useTrackTransactionStatus } = transactionServices;

    const [showPopupLogin,setShowPopupLogin] = useState(false);
    const [nftOwned,setNftOwned] = useState([]);
    const [nftStaked,setNftStaked] = useState([]);
    const [rewardNftStaked,setRewardNftStaked] = useState();
    const [transactionRunning,setTransactionRunning] = useState(false);


    const handleLogin = () => {
      setShowPopupLogin(true);
    }

    const handleLoginClose = () => {
      setShowPopupLogin(false);
    }
    const {isLoggedIn}=useGetLoginInfo();

    //useEffect
    useEffect(() => {
      getData();

      let interval = setInterval(getData,5000);
      return () => {
        clearInterval(interval);
      }

    },[]);

    //Functions
    const getData = async ()=>{
      axios.get(getNfts(network.apiAddress,address,idNft))
        .then(function(response){
          setNftOwned(response.data);
        });


        let abiRegistry = AbiRegistry.create(abiFile);
        let abi = new SmartContractAbi(abiRegistry, ["ElrondKnightStaking"]);

        let contract = new SmartContract({ address: new Address(contractAddressStaking), abi: abi });
        const proxy = new ProxyNetworkProvider(network.apiAddress);

        const queryRewards = new Query({
          address: new Address(contractAddressStaking),
          func: new ContractFunction('getNonceRewards'),
          args: [new AddressValue(new Address(address))]
        });

        proxy.queryContract(queryRewards)
          .then(function(queryResponse){
            let endpointDefinition = contract.getEndpoint("getNonceRewards");
            let { firstValue }  = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
            let nft = Array();
            let nftRewards={};
            firstValue.items.map((item) => {
              let nonce=parseInt(item.items[0].value,10).toString(16);
              if (nonce.length%2 !== 0){
                nonce="0"+nonce;
              }
              nftRewards[parseInt(item.items[0].value,10)] = parseInt(item.items[1].value,10);
              let idNftNonce=idNft+"-"+nonce;
              nft.push(idNftNonce);
            });
            setRewardNftStaked(nftRewards);

            if(nft.length !== 0){
              axios.get(getNft(network.apiAddress,idNft,nft.join("%2C")))
                .then(function(response){
                  setNftStaked(response.data);

                });
            }else{
              setNftStaked([]);
            }

          });
    }

    const stake = async (nonce) => {
      let argsNonce = nonce.toString(16);
      if (argsNonce.length%2!==0){
        argsNonce="0"+argsNonce;
      }
      let argsIdNft = Buffer(idNft).toString('hex');
      let contractAddress = new Address(contractAddressStaking);
      let argsContract = contractAddress.hex();

      const stakeTransaction = {
          value: 0,
          data: 'ESDTNFTTransfer@'+argsIdNft+'@'+argsNonce+'@01'+'@'+argsContract+'@7374616B654E6674',
          receiver: address,
          gasLimit: 60_000_000,
        };
      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
          transactions: stakeTransaction,
          transactionsDisplayInfo:{
              processingMessage: 'Processing transaction - Your knights are going to be staked',
              errorMessage: 'An error has occured during transaction',
              successMessage: 'Your knights are staked'
          },
          redirectAfterSign: false
      });
    }

    const stakeAll = async () => {

      let argsIdNft = Buffer(idNft).toString('hex');
      let contractAddress = new Address(contractAddressStaking);
      let argsContract = contractAddress.hex();

      let args="";
      let nb=0;
      nftOwned.map((nft) => {
        let argsNonce = nft.nonce.toString(16);
        if (argsNonce.length%2!==0){
          argsNonce="0"+argsNonce;
        }
        args+="@"+argsIdNft+"@"+argsNonce+"@01";
        nb ++;
      });

      let argsNb=nb.toString(16);
      if (argsNb.length%2!==0){
        argsNb="0"+argsNb;
      }


      const stakeAllTransaction = {
          value: 0,
          data: 'MultiESDTNFTTransfer@'+argsContract+'@'+argsNb+args+"@7374616B654E6674",
          receiver: address,
          gasLimit: 60_000_000,
        };
      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
          transactions: stakeAllTransaction,
          transactionsDisplayInfo:{
              processingMessage: 'Processing transaction - Your knights are going to be staked',
              errorMessage: 'An error has occured during transaction',
              successMessage: 'Your knights are staked'
          },
          redirectAfterSign: false
      });
    }

    const claimAll = async () => {

      const claimAllTransaction = {
          value: 0,
          data: 'claim',
          receiver: contractAddressStaking,
          gasLimit: 60_000_000,
        };
      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
          transactions: claimAllTransaction,
          transactionsDisplayInfo:{
              processingMessage: 'Processing transaction - Your money is on the road',
              errorMessage: 'An error has occured during transaction',
              successMessage: 'You get some $KNT'
          },
          redirectAfterSign: false
      });
    }

    const claimIndividually = async (nonce) => {
      let argsNonces="";

      let newNonce=parseInt(nonce,10).toString(16);
      if (newNonce.length%2 !== 0) {
        newNonce = "0"+newNonce;
      }
      argsNonces+="@"+newNonce;

      const unstakeTransaction = {
          value: 0,
          data: 'claimIndividually'+argsNonces,
          receiver: contractAddressStaking,
          gasLimit: 60_000_000,
        };
      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
          transactions: unstakeTransaction,
          transactionsDisplayInfo:{
              processingMessage: 'Processing transaction - Your money is ont the way',
              errorMessage: 'An error has occured during transaction',
              successMessage: 'Your money is arrived'
          },
          redirectAfterSign: false
      });
    }

    const unstakeAll = async () => {
      let argsNonces="";

      nftStaked.map((nft) => {
        let newNonce=parseInt(nft.nonce,10).toString(16);
        if (newNonce.length%2 !== 0) {
          newNonce = "0"+newNonce;
        }
        argsNonces+="@"+newNonce;
      });

      const unstakeTransaction = {
          value: 0,
          data: 'retrieveAndClaim'+argsNonces,
          receiver: contractAddressStaking,
          gasLimit: 60_000_000,
        };
      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
          transactions: unstakeTransaction,
          transactionsDisplayInfo:{
              processingMessage: 'Processing transaction - Your knights are going back',
              errorMessage: 'An error has occured during transaction',
              successMessage: 'You knights are arrived'
          },
          redirectAfterSign: false
      });

    }

    const unstake = async (nonce) => {
      let argsNonces="";

      let newNonce=parseInt(nonce,10).toString(16);
      if (newNonce.length%2 !== 0) {
        newNonce = "0"+newNonce;
      }
      argsNonces+="@"+newNonce;

      const unstakeTransaction = {
          value: 0,
          data: 'retrieveAndClaim'+argsNonces,
          receiver: contractAddressStaking,
          gasLimit: 60_000_000,
        };
      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
          transactions: unstakeTransaction,
          transactionsDisplayInfo:{
              processingMessage: 'Processing transaction - Your knights are going back',
              errorMessage: 'An error has occured during transaction',
              successMessage: 'You knights are arrived'
          },
          redirectAfterSign: false
      });
    }

    return (

        // <div className="Home1" Style='min-height:0;'>
        <div className="Home1 collectiblesSec">
            {(showPopupLogin && !isLoggedIn) && <PopupConnexion handleClose={handleLoginClose}/>}
            <Container>
                <Row>
                    <h2>Knight staking</h2>
                </Row>
                <Row>
                    <div className='ContourBlanc'>
                        <div className='textCollectibles'>
                            <p className='specialP'>STAKE YOUR KNIGHT TO EARN $KNT</p>
                            <p>Depending to the type of Knight you have (basic, rare or exclusive),
                                you will be able to earn $KNT through our staking program.</p>
                            <p>Take a look at our tokenomics paper if you wish to know more. </p>
                            {!isLoggedIn && <ButtonBlue onClick={handleLogin}>CONNECT YOUR WALLET</ButtonBlue>}
                        </div>
                        {isLoggedIn &&
                        <div className='knightStaking'>
                            <Col>
                                <Row>
                                    <h2>Your Knights</h2>
                                </Row>
                                <Row>
                                    <div className='buttonRow1'>
                                        <ButtonBlue onClick={claimAll}>CLAIM ALL REWARDS</ButtonBlue>
                                        <ButtonBlue onClick={stakeAll}>STAKE ALL KNIGHTS</ButtonBlue>
                                        <ButtonGrey onClick={unstakeAll}>UNSTAKE ALL KNIGHTS</ButtonGrey>
                                    </div>
                                </Row>
                                <Row className='text-center'>
                                    {
                                      nftStaked.map((nft)=>(
                                        <StakingCard
                                        onClickUnstake={() => unstake(nft.nonce)}
                                        onClickClaim={() => claimIndividually(nft.nonce)}
                                        image1={nft.url}
                                        txt1={nft.name}
                                        value1={(Math.round(parseInt(rewardNftStaked[nft.nonce],10)/(10**10)*1000))/1000}
                                        />
                                      ))
                                    }
                                    {/* UN */}
                                    {
                                      nftOwned.map((nft)=>(
                                        <UnStakedCard
                                        onClickStake={() => stake(nft.nonce)}
                                        image1={nft.url}
                                        txt1={nft.name}
                                        />
                                      ))
                                    }


                                    <Col></Col>
                                </Row>
                            </Col>
                        </div>
                      }
                    </div>
                </Row>
            </Container>
        </div>

    );
}

export default Collectibles;
