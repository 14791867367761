import {Card, CardImg} from 'react-bootstrap'
import {Container, Row, Col} from 'react-bootstrap'
import Carousel from "react-multi-carousel";

import "../Design/Home.css"
import "../Design/TeamCarousel.css"

import malak from '../Assets/team_malak.jpg';
import coconite from '../Assets/team_coconite.jpg';
import remi from '../Assets/team_remi.jpg';
import brooks from '../Assets/team_brooks.jpg';



function TeamCarousel(props) {

    //   TeamCards Carousel

 const dataCardsTeam = [
    {
        imageTeam: coconite,
        TitreTeam:'Coral. G',
        Pseudo:'Coconite',
        TxtTeam: 'Co-founder & Creative Director',
        TxtTeam2:'Artistic director since 2017 and In charge of the aesthetics and the global concept of Elrond Knights, from pixel art to game experience and production.',
    },
    {
        imageTeam: malak,
        TitreTeam:'Tristan. M',
        Pseudo:'Dark Malak',
        TxtTeam: 'Co-founder & Marketing Director',
        TxtTeam2:'Craftsman and entrepreneur since 2016, Blockchain enthusiast since 2012. In charge of Elrond knight\'s business development and Blockchain strategy.',
    },
    {
        imageTeam: remi,
        TitreTeam:'Remi. L',
        Pseudo:'Big Mire',
        TxtTeam: 'Developer & Engineer Web3',
        TxtTeam2:'Engineer, smart-contract developer and Web3 enthusiast. In charge of conception and development of the platform and smart-contracts.',
    },
    {
        imageTeam: brooks,
        TitreTeam:'Brooks',
        Pseudo:'Brooks',
        TxtTeam: 'Community Admin',
        TxtTeam2:'Mechanical engineer and blockchain enthusiast, I\'m the high-flying community admin of the brotherhood.',
    }

  ]

  const responsive2 = {

    mobile: {
      breakpoint: { max: 790, min: 0 },
      items: 1
    }
  };


    return (

        <div className='cardsTeam' id="team">
                        <Carousel responsive={responsive2}  swipeable={true} draggable={true} infinite={true}
                        itemClass="carousel-item-center" autoPlaySpeed={6000} showDots={true} className='carouselTeam'>
                        {dataCardsTeam.map(data=>{
                            return (

                                <div className="cardTeam">

                                    <Card Style={'text-align:center;'}>
                                        <Row>
                                            <Card.Body>
                                                <Col sm={12} md={6} lg={3} Style="padding: 30px;">
                                                    <Row className='imgCT3'>
                                                        <img src={data.imageTeam} alt=""/>
                                                    </Row>
                                                    <Row>
                                                        <Card.Title>
                                                            <h3>{data.TitreTeam}</h3>
                                                        </Card.Title>
                                                    </Row>
                                                    <Row>
                                                        <p className='pCaps'>{data.Pseudo}</p>
                                                    </Row>

                                                    <Row>
                                                        <p className='pCaps'>{data.TxtTeam}</p>
                                                    </Row>
                                                    <Row>
                                                        <p>{data.TxtTeam2}</p>
                                                    </Row>
                                                </Col>
                                            </Card.Body>
                                        </Row>
                                    </Card>
                            </div>

                            )
                        })
                        }
                        </Carousel>
                    </div>

    )
}
export default TeamCarousel;
